.navbar {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.navbutton {
  padding-top: 1rem;
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 600;
}
.navbutton:focus {
  outline: none;
}

button {
  position: relative;
}
