i.spinner.icon {
  right: 110px !important;
  display: none !important;
}
.saving i.spinner.icon {
  display: inline-block !important;
}
.dimmer-scope {
  position: relative;
}
.dimmer-scope .loader {
  width: 1.28571429em !important;
  height: 1.28571429em !important;
}
.dimmer-scope .loader::before {
  width: 1.28571429em;
  height: 1.28571429em;
}
.dimmer-scope .loader::after {
  width: 1.28571429em;
  height: 1.28571429em;
}

.rememberCheck {
  float: left;
  margin-top: 7px;
  margin-left: 8px;
}

@media (max-width: 420px) {
  .ui.icon.input.inputItem > input {
    padding-right: 1em !important;
  }
}
@media (max-width: 370px) {
  .ui.icon.input.inputItem > input {
    width: 150px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

.checklist_inputMargin__8rT41 {
  margin: 5px 10px !important;
}
.checklist_loadingText__Vbjys {
  display: block;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  font-weight: bold;
}
@media (min-width: 371px) {
  .checklist_historyList__wgfNv {
    width: 22rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 370px) {
  .checklist_inputMargin__8rT41 {
    margin: 5px 0 !important;
  }
}
@media (min-width: 421px) {
  .checklist_itemsList__1DJ9i {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}
@media (max-width: 689px) {
  .checklist_clearHistoryBtn__VZGGo {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 690px) {
  .checklist_clearHistoryBtn__VZGGo {
    margin-left: 0.8rem !important;
    margin-top: 0.45rem !important;
    position: absolute !important;
  }
}

.popup_popup__1mhJB {
  position: absolute;
  top: 36px;
  right: 0;
  font-size: 1rem;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: 0.833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}
.popup_popup__1mhJB::before {
  top: -0.30714286em;
  right: 0.75em;
  bottom: auto;
  left: auto;
  margin-left: 0;
  box-shadow: -1px -1px 0 0 #bababc;
  background: #fff;
  position: absolute;
  content: "";
  width: 0.71428571em;
  height: 0.71428571em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 2;
}

.navbar_navbar__kHKUd {
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.navbar_navbutton__1qF8l {
  padding-top: 1rem;
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 600;
}
.navbar_navbutton__1qF8l:focus {
  outline: none;
}

button {
  position: relative;
}

.checklistHeading_headerInput__17tBs > div {
  opacity: 1 !important;
}
.checklistHeading_headerInput__17tBs input {
  max-width: 250px !important;
  text-align: center !important;
  border: none !important;
  font-size: 1.8rem !important;
  padding: 0em 0em 1em 0 !important;
  color: rgba(0, 0, 0, 0.9) !important;
  font-weight: bold !important;
}
.checklistHeading_headerInput__17tBs input:disabled {
  color: rgba(255, 0, 0, 1) !important;
}
.checklistHeading_headerInput__17tBs input:focus {
  outline: none !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

