.headerInput > div {
  opacity: 1 !important;
}
.headerInput input {
  max-width: 250px !important;
  text-align: center !important;
  border: none !important;
  font-size: 1.8rem !important;
  padding: 0em 0em 1em 0 !important;
  color: rgba(0, 0, 0, 0.9) !important;
  font-weight: bold !important;
}
.headerInput input:disabled {
  color: rgba(255, 0, 0, 1) !important;
}
.headerInput input:focus {
  outline: none !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
