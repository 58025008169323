.inputMargin {
  margin: 5px 10px !important;
}
.loadingText {
  display: block;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  font-weight: bold;
}
@media (min-width: 371px) {
  .historyList {
    width: 22rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 370px) {
  .inputMargin {
    margin: 5px 0 !important;
  }
}
@media (min-width: 421px) {
  .itemsList {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
}
@media (max-width: 689px) {
  .clearHistoryBtn {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 690px) {
  .clearHistoryBtn {
    margin-left: 0.8rem !important;
    margin-top: 0.45rem !important;
    position: absolute !important;
  }
}
