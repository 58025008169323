.popup {
  position: absolute;
  top: 36px;
  right: 0;
  font-size: 1rem;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: #fff;
  padding: 0.833em 1em;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}
.popup::before {
  top: -0.30714286em;
  right: 0.75em;
  bottom: auto;
  left: auto;
  margin-left: 0;
  box-shadow: -1px -1px 0 0 #bababc;
  background: #fff;
  position: absolute;
  content: "";
  width: 0.71428571em;
  height: 0.71428571em;
  transform: rotate(45deg);
  z-index: 2;
}
