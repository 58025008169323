i.spinner.icon {
  right: 110px !important;
  display: none !important;
}
.saving i.spinner.icon {
  display: inline-block !important;
}
.dimmer-scope {
  position: relative;
}
.dimmer-scope .loader {
  width: 1.28571429em !important;
  height: 1.28571429em !important;
}
.dimmer-scope .loader::before {
  width: 1.28571429em;
  height: 1.28571429em;
}
.dimmer-scope .loader::after {
  width: 1.28571429em;
  height: 1.28571429em;
}

.rememberCheck {
  float: left;
  margin-top: 7px;
  margin-left: 8px;
}

@media (max-width: 420px) {
  .ui.icon.input.inputItem > input {
    padding-right: 1em !important;
  }
}
@media (max-width: 370px) {
  .ui.icon.input.inputItem > input {
    width: 150px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
